import { ReactComponent as LogoGBN } from '../assets/svg/gbnlogo.svg';
import { ReactComponent as LogoTwilio } from '../assets/svg/twilioflex.svg';
import { ReactComponent as Tier } from '../assets/svg/tier.svg';
import { ReactComponent as TierSAT } from '../assets/svg/tier-sat.svg';
import { ReactComponent as TierPro } from '../assets/svg/protier.svg';
import { ReactComponent as TierStandard } from '../assets/svg/standardtier.svg';
import { ReactComponent as SentimentAnalysisTicket } from '../assets/svg/sentiment-analysis-ticket.svg';
import { ReactComponent as AddLineItem } from '../assets/svg/addLineLogo.svg';

const ThemeGreen = {
  primaryDark: '#79aa53',
  primaryLight: '#e5fdd2',
  secondaryLight: '#fac97f',
  secondarySuperDark: '#764306',
  secondaryDark: '#ea9b3f',
  success: '#baf28e',
};

const ThemeBlue = {
  primaryDark: '#3977ce',
  primaryLight: '#3977ce23',
  secondaryLight: '#3977ce',
  secondaryDark: '#3977ce',
  secondarySuperDark: '#4889E4',
  success: '#3977ce',
};

export const Apps = {
  [process.env.REACT_APP_GBN]: {
    themes: ThemeGreen,
    appName: 'Gender by name',
    logo: LogoGBN,
    data: {
      production: {
        tiers: [
          {
            id: 'free_tier',
            name: 'Free',
            credits: 100,
            price: 0,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1MvjBBEvqiBHLt7prujwO9xf',
            name: 'Starter',
            credits: 5000,
            price: 9,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1MvjB5EvqiBHLt7po6tdfXfr',
            name: 'Pro',
            credits: 25000,
            price: 29,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1NFcIiEvqiBHLt7p9dEQUl3g',
            name: 'Ultra',
            credits: 100000,
            price: 99,
            pricePeriod: '/month',
            icon: Tier,
          },
        ],
        addon: {
          9: {
            id: 'price_1NIXYVEvqiBHLt7pctS2Ceoo',
            max: 5000,
          },
          29: {
            id: 'price_1NIXYoEvqiBHLt7pPWikWTTv',
            max: 25000,
          },
          99: {
            id: 'price_1NIXZ7EvqiBHLt7pvr6zeKoe',
            max: 100000,
          },
          169: {
            id: 'price_1NIXZMEvqiBHLt7pOqiVkbr8',
            max: 250000,
          },
          299: {
            id: 'price_1NIXZVEvqiBHLt7pDm2UJlOp',
            max: 500000,
          },
          499: {
            id: 'price_1NIXZfEvqiBHLt7pBY12S3TW',
            max: 1000000,
          },
        },
      },
      development: {
        tiers: [
          {
            id: 'free_tier',
            name: 'Free',
            credits: 100,
            price: 0,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1Mvj0wEvqiBHLt7pSgU0H9Hj',
            name: 'Starter',
            credits: 5000,
            price: 9,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1Mvj3aEvqiBHLt7pQmRhoKzQ',
            name: 'Pro',
            credits: 25000,
            price: 29,
            pricePeriod: '/month',
            icon: Tier,
          },
          {
            id: 'price_1NFcJQEvqiBHLt7p1rLiLOjW',
            name: 'Ultra',
            credits: 100000,
            price: 99,
            pricePeriod: '/month',
            icon: Tier,
          },
        ],
        addon: {
          9: {
            id: 'price_1NIXQgEvqiBHLt7prLHqeBwN',
            max: 5000,
          },
          29: {
            id: 'price_1NIXUbEvqiBHLt7pJNk3QXYY',
            max: 25000,
          },
          99: {
            id: 'price_1NIXUgEvqiBHLt7paQuRiAI2',
            max: 100000,
          },
          169: {
            id: 'price_1NIXV6EvqiBHLt7p3Rzjxjn0',
            max: 250000,
          },
          299: {
            id: 'price_1NIXVHEvqiBHLt7pRwWgIvHy',
            max: 500000,
          },
          499: {
            id: 'price_1NIXVMEvqiBHLt7pUgcemYUS',
            max: 1000000,
          },
        },
      },
    },
  },
  [process.env.REACT_APP_TWILIO]: {
    themes: ThemeBlue,
    appName: 'Twilio',
    logo: LogoTwilio,
    data: {
      production: {
        tiers: [
          {
            id: 'price_1OIUSOEvqiBHLt7pg4adcxgq',
            name: 'Base',
            credits: 20,
            price: 19,
            seats: 20,
            customObjects: 0,
            pricePeriod: ' per user per month',
            icon: TierStandard,
            features: ['Up to 20 users', 'Standard objects only', 'Standard support'],
          },
          {
            id: 'price_1OIUSiEvqiBHLt7pCLSF44Fe',
            name: 'Intermediate',
            icon: TierPro,
            credits: 100,
            price: 39,
            seats: 100,
            customObjects: 2,
            pricePeriod: ' per user per month',
            features: ['Up to 100 users', '2 Custom Objects', 'Standard support'],
          },
          {
            id: 'price_1OuEkTEvqiBHLt7pW5AhnUBC',
            name: 'Enterprise',
            icon: TierPro,
            credits: 10000,
            price: 109,
            seats: 10000,
            customObjects: 10000,
            pricePeriod: ' per user per month',
            features: [
              '100+ users',
              'Unlimited Custom Objects',
              'Priority support and custom onboarding',
            ],
          },
        ],
      },
      development: {
        tiers: [
          {
            id: 'price_1OCj9yEvqiBHLt7phuVwYj46',
            name: 'Base',
            credits: 20,
            price: 19,
            seats: 20,
            customObjects: 0,
            pricePeriod: ' per user per month',
            icon: TierStandard,
            features: ['Up to 20 users', 'Standard objects only', 'Standard support'],
          },
          {
            id: 'price_1OIUTaEvqiBHLt7pJyjrbM3z',
            name: 'Intermediate',
            icon: TierPro,
            credits: 100,
            price: 39,
            seats: 100,
            customObjects: 2,
            pricePeriod: ' per user per month',
            features: ['Up to 100 users', '2 Custom Objects', 'Standard support'],
          },
          {
            id: 'price_1OeIbBEvqiBHLt7pgUn4DofZ',
            name: 'Enterprise',
            icon: TierPro,
            credits: 10000,
            price: 109,
            seats: 10000,
            customObjects: 10000,
            pricePeriod: ' per user per month',
            features: [
              '100+ users',
              'Unlimited Custom Objects',
              'Priority support and custom onboarding',
            ],
          },
        ],
      },
    },
  },
  [process.env.REACT_APP_SENTIMENT_ANALYSIS_TICKET]: {
    themes: ThemeBlue,
    appName: 'Sentiment Analysis',
    logo: SentimentAnalysisTicket,
    data: {
      production: {
        tiers: [
          {
            id: 'price_1NYU1DEvqiBHLt7pfmDKutmu',
            name: 'Starter',
            oldPlan: true,
            credits: 500,
            creditsLabel: 'Process up to 500 tickets per month',
            price: 9,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1NYU1sEvqiBHLt7pzpPNTYfx',
            name: 'Pro',
            oldPlan: true,
            icon: TierSAT,
            credits: 2000,
            creditsLabel: 'Process up to 2.000 tickets per month',
            price: 29,
            pricePeriod: '/month',
          },
          {
            id: 'price_1NYU2QEvqiBHLt7pvFBa60hd',
            name: 'Ultra',
            oldPlan: true,
            credits: 5000,
            creditsLabel: 'Process up to 5.000 tickets per month',
            price: 49,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1NaftsEvqiBHLt7pBSzoKAya',
            name: 'Enterprise',
            oldPlan: true,
            credits: 25000,
            creditsLabel: 'Process up to 25.000 tickets per month',
            price: 99,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'free_tier',
            name: 'Free',
            credits: 50,
            creditsLabel: 'Process up to 50 tickets per month',
            price: 0,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqPuEvqiBHLt7pMeIDA0lr',
            name: 'Starter',
            credits: 500,
            creditsLabel: 'Process up to 500 tickets per month',
            price: 19,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqQnEvqiBHLt7pavlSZC8G',
            name: 'Pro',
            icon: TierSAT,
            credits: 2000,
            creditsLabel: 'Process up to 2.000 tickets per month',
            price: 49,
            pricePeriod: '/month',
          },
          {
            id: 'price_1OYqREEvqiBHLt7p72Q35V46',
            name: 'Ultra',
            credits: 5000,
            creditsLabel: 'Process up to 5.000 tickets per month',
            price: 99,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqSGEvqiBHLt7p6k8dgXwe',
            name: 'Enterprise',
            credits: 25000,
            creditsLabel: 'Process up to 25.000 tickets per month',
            price: 199,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'extra',
            name: 'Extra',
            icon: TierSAT,
            credits: '>25.000',
            creditsLabel: 'Process >25.000 tickets per month',
            price: 'Contact Sales',
          },
        ],
      },
      development: {
        tiers: [
          {
            id: 'price_1NYU1BEvqiBHLt7plMCooaLJ',
            name: 'Starter',
            oldPlan: true,
            credits: 500,
            creditsLabel: 'Process up to 500 tickets per month',
            price: 9,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1NYU1uEvqiBHLt7pnJVHJ1W4',
            name: 'Pro',
            oldPlan: true,
            icon: TierSAT,
            credits: 2000,
            creditsLabel: 'Process up to 2.000 tickets per month',
            price: 29,
            pricePeriod: '/month',
          },
          {
            id: 'price_1NYU2OEvqiBHLt7pCAv0Un2l',
            name: 'Ultra',
            oldPlan: true,
            credits: 5000,
            creditsLabel: 'Process up to 5.000 tickets per month',
            price: 49,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1NaGS7EvqiBHLt7pUR8Dl6jr',
            name: 'Enterprise',
            oldPlan: true,
            credits: 25000,
            creditsLabel: 'Process up to 25.000 tickets per month',
            price: 99,
            pricePeriod: '/month',
            icon: TierSAT,
          },

          {
            id: 'free_tier',
            name: 'Free',
            credits: 50,
            creditsLabel: 'Process up to 50 tickets per month',
            price: 0,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqvsEvqiBHLt7p1fJ8xUKw',
            name: 'Starter',
            credits: 500,
            creditsLabel: 'Process up to 500 tickets per month',
            price: 19,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqwFEvqiBHLt7p4zBPop3i',
            name: 'Pro',
            icon: TierSAT,
            credits: 2000,
            creditsLabel: 'Process up to 2.000 tickets per month',
            price: 49,
            pricePeriod: '/month',
          },
          {
            id: 'price_1OYqwbEvqiBHLt7pyKMHBoHf',
            name: 'Ultra',
            credits: 5000,
            creditsLabel: 'Process up to 5.000 tickets per month',
            price: 99,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'price_1OYqwwEvqiBHLt7pfRwYxOra',
            name: 'Enterprise',
            credits: 25000,
            creditsLabel: 'Process up to 25.000 tickets per month',
            price: 199,
            pricePeriod: '/month',
            icon: TierSAT,
          },
          {
            id: 'extra',
            name: 'Extra',
            icon: TierSAT,
            credits: '>25.000',
            creditsLabel: 'Process >25.000 tickets per month',
            price: 'Contact Sales',
          },
        ],
      },
    },
  },
  [process.env.REACT_APP_WORKFLOWTOOLBOX]: {
    themes: ThemeGreen,
    appName: 'WorkflowToolbox',
  },
  [process.env.REACT_APP_GDPR]: {
    themes: ThemeGreen,
    appName: 'GDPR',
  },
  [process.env.REACT_APP_ATTACHMENT_FROM_FORM]: {
    themes: ThemeBlue,
    appName: 'ATTACHMENT FROM FORM',
  },
  [process.env.REACT_APP_FUTURE_DATE]: {
    themes: ThemeBlue,
    appName: 'FUTURE DATE',
  },
  [process.env.REACT_APP_ATTACH_FILE_IN_PROPERTIES]: {
    themes: ThemeGreen,
    appName: 'ATTACH FILE IN PROPERTIES',
  },
  [process.env.REACT_APP_ADD_LINE]: {
    themes: ThemeBlue,
    appName: 'AddLine',
    logo: AddLineItem,
    data: {
      production: {
        tiers: [
          {
            id: 'free_tier',
            name: 'Free',
            credits: 1,
            creditsLabel: '',
            price: 0,
            pricePeriod: '/month',
            icon: TierSAT,
            features: [
              'Dinamically add a Line item to a deal via workflow action',
              'Includes line items basic properties',
            ],
            featuresTitle: ' ',
          },
          {
            id: 'price_1OMcSNEvqiBHLt7ptG2qxEUg',
            name: 'Pro',
            credits: 2,
            creditsLabel: '',
            price: 9,
            pricePeriod: '/month',
            icon: TierSAT,
            featuresTitle: ' ',
            features: [
              'Dinamically add and bulk update line items associated to a deal',
              'Includes advanced and custom properties',
            ],
          },
        ],
      },
      development: {
        tiers: [
          {
            id: 'free_tier',
            name: 'Free',
            credits: 1,
            creditsLabel: '',
            price: 0,
            pricePeriod: '/month',
            icon: TierSAT,
            features: [
              'Dinamically add a Line item to a deal via workflow action',
              'Includes line items basic properties',
            ],
            featuresTitle: ' ',
          },
          {
            id: 'price_1NklRlEvqiBHLt7pt0EVlpAF',
            name: 'Pro',
            credits: 2,
            creditsLabel: '',
            price: 9,
            pricePeriod: '/month',
            icon: TierSAT,
            featuresTitle: ' ',
            features: [
              'Dinamically add and bulk update line items associated to a deal',
              'Includes advanced and custom properties',
            ],
          },
        ],
      },
    },
  },
  [process.env.REACT_APP_MULTIPLE_FILES_DOWNLOAD]: {
    themes: ThemeBlue,
    appName: 'MULTIPLE FILES DOWNLOAD',
  },
  [process.env.REACT_APP_ATTACHMENT_FILTER]: {
    themes: ThemeBlue,
    appName: 'HAS ATTACHMENT FILTER',
  },
  [process.env.REACT_APP_BULK_DELETE_ATTACHMENT]: {
    themes: ThemeBlue,
    appName: 'BULK DELETE ATTACHMENT',
  },
  [process.env.REACT_APP_SHOW_LINE_ITEM_ASSOCIATIONS]: {
    themes: ThemeBlue,
    appName: 'SHOW LINE ITEM ASSOCIATIONS',
  },
  [process.env.BUSINESS_DAYS_CALCULATOR]: {
    themes: ThemeBlue,
    appName: 'BUSINESS DAYS CALCULATOR',
  },
};
