import React, { createContext, useState } from 'react';

export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState({
    isLoading: false,
    error: null,
  });

  return <StatusContext.Provider value={[status, setStatus]}>{children}</StatusContext.Provider>;
};

export const useStatus = () => {
  return React.useContext(StatusContext);
};
