import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
//import HowToPage from '../pages/HowToPage';

const AuthPage = lazy(() => import('../pages/AuthPage'));
const SettingsPage = lazy(() => import('../pages/SettingsPage'));
const BillingPage = lazy(() => import('../pages/BillingPage'));
const SubscriptionPage = lazy(() => import('../pages/SubscriptionPage'));
const SubscriptionStripeOutcomePage = lazy(() => import('../pages/SubscriptionStripeOutcomePage'));
const SupportForm = lazy(() => import('../pages/SupportForm/SupportForm'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const ConsentPage = lazy(() => import('../pages/ConsentPage'));

function AppRouter() {
  const router = useRoutes([
    {
      path: '/',
      element: <AuthPage />,
    },
    {
      path: '/support',
      element: <SupportForm />,
    },
    {
      path: '/:app_id',
      children: [
        {
          path: '/:app_id/subscription',
          element: <SubscriptionPage />,
        },
        {
          path: '/:app_id/subscription/:tier_id',
          element: <SubscriptionPage />,
        },
        {
          path: '/:app_id/subscription/:portal_id/stripe/:outcome',
          element: <SubscriptionStripeOutcomePage />,
        },
        {
          path: '/:app_id/settings',
          element: <SettingsPage />,
        },
        {
          path: '/:app_id/settings/:step',
          element: <SettingsPage />,
        },
        {
          path: '/:app_id/consent',
          element: <ConsentPage />,
        },
        {
          path: '/:app_id/billing/addon',
          element: <BillingPage />,
        },
        {
          path: '/:app_id/billing/:tier_id',
          element: <BillingPage />,
        },
        {
          path: '/:app_id/editbilling',
          element: <BillingPage />,
        },
        /*{
          path: '/:app_id/howto',
          element: <HowToPage />,
        },*/
      ],
    },
    {
      path: '/error',
      element: <ErrorPage />,
    },
    {
      path: '*',
      element: <ErrorPage />,
    },
  ]);

  return router;
}
export default AppRouter;
