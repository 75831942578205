import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRouter from './routers';
import './assets/css/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Apps } from './data/hubspotApps';

const ENVIRONMENT = process.env.REACT_APP_ENV;

function changeRootVars(theme) {
  const root = document.documentElement;
  root.style.setProperty('--primaryDark', theme ? theme.primaryDark : '#79aa53');
  root.style.setProperty('--primaryLight', theme ? theme.primaryLight : '#e5fdd2');
  root.style.setProperty('--secondaryLight', theme ? theme.secondaryLight : '#fac97f');
  root.style.setProperty('--secondarySuperDark', theme ? theme.secondarySuperDark : '#764306');
  root.style.setProperty('--secondaryDark', theme ? theme.secondaryDark : '#ea9b3f');
  root.style.setProperty('--success', theme ? theme.success : '#baf28e');
}

function App() {
  const app_id = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!app_id) return;
    changeRootVars(Apps?.[app_id]?.themes);
  }, [app_id]);

  useEffect(() => {
    // Hubspot tracking code only in production
    if (ENVIRONMENT !== 'production') return;
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/3963388.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <div id="app">
          <AppRouter />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
